<template>
  <div class="goodsGetails">
    <div class="navS" v-if="shopNav">
      <div class="back" @click="backClick">
        <i class="el-icon-arrow-left"></i> 返回
      </div>
    </div>
    <!-- 顶部nav -->
    <knowledgeServiceNav :index="2"></knowledgeServiceNav>

    <!-- 商品详情 -->
    <GoodsGetailsInfo v-if="goodsData" :goodsData="goodsData" />

    <!-- 为你推荐 -->
    <!-- <Recommended v-if="goodsData" :fuID="goodsData.parentId" /> -->

    <!-- 底部tabs  -->
    <GoodsBottomTab v-if="goodsData" :goodsData="goodsData" />
  </div>
</template>

<script>
import { searchID } from "api/goodsGetails";
import knowledgeServiceNav from "components/KnowledgeServiceComponent/knowledgeServiceNav";
import GoodsGetailsInfo from "components/GoodsGetailsComponent/GoodsGetailsInfo";
// import Recommended from "components/GoodsGetailsComponent/Recommended";
import GoodsBottomTab from "components/GoodsGetailsComponent/GoodsBottomTab";

export default {
  name: "goodsGetails",
  components: {
    GoodsBottomTab,
    knowledgeServiceNav,
    GoodsGetailsInfo,
    // Recommended,
  },
  data() {
    return {
      // 商品详情
      goodsData: false,
      shopNav: false,
    };
  },

  methods: {
    // 根据ID查询商品
    async goodsdata(id) {
      const params = {
        productId: id,
      };
      // form.append("");
      let res = await searchID(params);
      // console.log(res);
      if (res.code === 200) {
        this.goodsData = res.data;
      } else {
        this.$message({
          type: "error",
          message: "获取商品信息失败",
        });
      }
    },

    backClick() {
      this.$router.go(-1);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.shopNav) {
        // vm.$store.commit("createdShowNavfun");
        window.onheaderShow();
        vm.shopNav = true;
      }
    });
  },

  watch: {
    $route(to, from) {
      this.goodsdata(this.$route.params.id);
    },
  },

  mounted() {
    if (this.$store.state.shopNav) {
      this.shopNav = true;

      window.onheaderShow();
    } else {
      this.shopNav = false;
    }
  },
  created() {
    this.goodsdata(this.$route.params.id);
  },
  // beforeDestroy() {
  //   this.$store.commit("beforeShowNavfun");
  // },
};
</script>

<style lang="scss" scoped>
.goodsGetails {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  .navS {
    position: relative;
    display: flex;
    top: -25px;
    .back {
      cursor: pointer;
      padding: 10px 20px 10px 0;
    }
  }
}
</style>
