<template>
  <div class="specifications">
    <div class="group_box" v-if="productType != 1">
      <div
        class="group"
        v-for="(group, groupIndex) in specificationList"
        :key="groupIndex"
      >
        <div
          :class="['group-item', selectedIndex === groupIndex ? 'active' : '']"
          @click="handleSelct(group, groupIndex)"
        >
          <span class="block">{{ group.name }} </span>
        </div>
      </div>
    </div>

    <div class="resetArr_box" v-else>
      <div class="resetArr">
        <div
          class="resetArr_item"
          :class="resetArrIndex == i ? 'active' : ''"
          @click="handleClick(v, i)"
          v-for="(v, i) in resetArr"
          :key="i"
        >
          {{ v.name }}
        </div>
      </div>

      <div class="resetArr top">
        <div
          class="resetArr_item"
          :class="childIndex == i ? 'active' : ''"
          @click="handleChildClick(v, i)"
          v-for="(v, i) in resetArr[resetArrIndex].child"
          :key="i"
        >
          {{ v.remarks }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    specificationList: {
      default: () => [],
    },
    productType: {},
    needtime: {
      default: 0,
    },
    undertaker: {},
  },
  data() {
    return {
      selectedIndex: 0,
      resetArr: [],
      resetArrIndex: 0,
      childIndex: 0,
    };
  },
  methods: {
    handleSelct(item, groupIndex) {
      this.selectedIndex = groupIndex;
      this.$emit("handleSelect", item);
    },

    reset() {
      let arr = [
        {
          name: "VIP",
          child: [],
        },
        {
          name: "SVIP",
          child: [],
        },
        // {
        //   name: "PLUS",
        //   child: [],
        // },
      ];
      this.specificationList.forEach((item) => {
        if (item.name == "VIP") {
          arr[0].child.push(item);
        } else if (item.name == "SVIP") {
          arr[1].child.push(item);
        }
      });

      this.resetArr = arr;
    },

    handleClick(v, i) {
      console.log(v, i);
      this.resetArrIndex = i;
      this.childIndex = 0;
      this.$emit("handleSelect", v.child[0]);
    },

    handleChildClick(v, i) {
      this.childIndex = i;
      this.$emit("handleSelect", v);
    },
  },
  watch: {
    specificationList(val) {
      this.reset();
      if (this.$route.params.type) {
        if (this.$route.params.type * 1 > 3) {
          return;
        }
        this.resetArrIndex = this.$route.params.type * 1 - 1;
        this.handleClick(this.resetArr[this.resetArrIndex], this.resetArrIndex);
      }
    },
  },
  mounted() {},
  created() {
    // console.log(this.$route.params);
  },
};
</script>

<style lang="scss" scoped>
.group_box {
  display: flex;
}

.resetArr {
  display: flex;
  .resetArr_item {
    margin: 5px 5px;
    padding: 10px 8px;
    background: #ffffff;
    border: 1px solid #cccccc;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid #eb441e;
    }
  }

  .active {
    border: 1px solid #eb441e;
  }
}
.dis {
  display: block !important;
}
.specifications {
  .group {
    margin: 5px 5px;
    @include flex-start;
    &-item {
      @include noselect;
      @include flex-start;
      .block {
        padding: 10px 8px;
        background: #ffffff;
        border: 1px solid #cccccc;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }

      & > .block:hover {
        border: 1px solid #eb441e;
      }
      &.active > .block {
        border: 1px solid #eb441e;
      }
      .select-desc {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
