<template>
  <div class="GoodsGetailsInfo">
    <div class="copyright-practice-detail-content">
      <div class="copyright-practice-detail-content-main">
        <div class="copyright-practice-detail-content-main-picgroup">
          <PictureShowcase :imgList="imgList" v-if="imgList" height="380px" />
        </div>
        <div class="copyright-practice-detail-content-main-desc">
          <div class="title">{{ goodsData.productName }}</div>
          <div class="product-info">
            商品编码&nbsp;|&nbsp;{{ goodsData.productCode }}&nbsp;
          </div>
          <div class="block">
            <div class="leftTemp">
              <div class="specification">
                <span class="space" v-if="goodsData.productType == 7">
                  版本
                </span>
                <span
                  class="space"
                  v-if="
                    goodsData.productType == 5 || goodsData.productType == 6
                  "
                  >数据
                </span>

                <span
                  class="space"
                  v-if="
                    goodsData.productType != 7 &&
                    goodsData.productType != 5 &&
                    goodsData.productType != 6
                  "
                >
                  规格
                </span>
                <div class="shuju">
                  <div
                    v-if="
                      goodsData.productType == 5 ||
                      goodsData.productType == 6 ||
                      goodsData.productType == 7
                    "
                  >
                    {{ goodsSpecifications[0].remarks }}
                  </div>

                  <Specifications
                    v-else
                    :undertaker="undertaker"
                    :needtime="needtime"
                    :productType="goodsData.productType"
                    :specificationList="goodsSpecifications"
                    @handleSelect="handleSelect"
                  />

                  <div
                    @click="bgimgClick"
                    class="bgimg"
                    v-if="goodsData.productType == 6"
                  ></div>
                </div>
              </div>
              <div
                class="price"
                :class="goodsData.productPrices == 0 ? '' : 'margin'"
              >
                <span class="space">价格</span>
                <span class="txt">
                  <div class="small">
                    <img
                      class="Double11"
                      :src="require('assets/images/11.png')"
                      alt=""
                    />
                    ￥{{
                      specificationitem.discountPrice
                        ? specificationitem.discountPrice.toFixed(2)
                        : specificationitem.price + ".00"
                    }}
                    {{
                      (goodsData.productType == 5 ||
                        goodsData.productType == 6 ||
                        goodsData.productType == 7) &&
                      goodsData.productPrices != 0
                        ? "/年"
                        : ""
                    }}
                    <span class="BeforePrice"
                      >￥{{ specificationitem.price + ".00" }}</span
                    >
                  </div>
                  <!-- <div
                    class="vip_price"
                    v-if="
                      goodsData.productPrices != 0 &&
                      goodsData.productType != 2 &&
                      goodsData.productType != 1
                    "
                  >
                    ￥{{ goodsData.memberPrices }}
                    <div class="text">专享价</div>

                    <span>VIP/SVIP/PLUS会员</span>
                  </div> -->
                </span>
              </div>
              <div class="last">
                <span class="text"> 应用软件 </span>
                <span class="busin">{{
                  goodsData.productType == 5 ||
                  goodsData.productType == 6 ||
                  goodsData.productType == 7
                    ? "成语（极速版）、writemall网站、成语文库（小程序）、成语Office/WPS版、成语office（APP）"
                    : "成语（极速版）、writemall网站、成语文库（小程序）、成语Office/WPS版、成语office（APP）"
                }}</span>
              </div>

              <div class="last">
                <span class="text"> 服务提供商 </span>
                <span class="busin">深圳写手智能科技有限公司 </span>
              </div>
            </div>
            <!-- <div class="right">
              <span class="evaltxt">累计评价</span>
              <span class="evalnum">50万+</span>
            </div> -->
          </div>

          <div class="addshop">
            <!-- <CopyrightCounter
              :productType="1"
              @childByValue="childByValue"
              v-if="goodsData.productPrices != 0"
            /> -->
            <div class="shopcarBtn" @click="addToShopcar(goodsData)">
              {{ goodsData.productPrices != 0 ? "立即购买" : "立即添加" }}
            </div>
            <!-- v-if="goodsData.memberPay == false"
            <div v-else class="shopcarBtn" @click="IsVIPFreeAdd">立即添加</div> -->
          </div>
        </div>
      </div>
      <!-- <div class="copyright-practice-detail-content-sets">
        <div @click="lick" class="collect">
          <img
            v-if="!like"
            :src="require('assets/images/goodsGetails/like.png')"
            alt=""
          />
          <img
            v-if="like"
            :src="require('assets/images/goodsGetails/like-red.png')"
            alt=""
          />
          收藏
        </div>
      </div> -->
    </div>

    <!-- 立即购买弹窗 -->
    <el-dialog
      width="500px"
      :close-on-click-modal="false"
      title="确认订单"
      :visible.sync="addShopDialog"
      class="addShopDialog"
    >
      <div class="shop_box">
        <div class="shop_left">
          <img
            class="img"
            :src="
              imgList
                ? $store.state.onlineBasePath + imgList[0].fileUrl
                : require('assets/images/no-shop-img100.png')
            "
            alt=""
          />
        </div>
        <div class="shop_right">
          <div class="txet_top">购买{{ goodsData.productName }}</div>
          <div class="text_bottom">
            <div class="text_left">
              单价: ¥{{
                specificationitem.price ? specificationitem.price : price
              }}
            </div>
            <div class="text_center">数量: {{ sum }}</div>
            <div class="text_right">
              ¥
              {{
                specificationitem.price
                  ? (specificationitem.price * sum).toFixed(2)
                  : price.toFixed(2)
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="coupons_box">
        <!-- <div class="coupons_top">
          <i class="icon"></i>
          优惠码

          <el-input
            maxlength="6"
            @input="codeHandelInput(codeInput)"
            v-model="codeInput"
            placeholder="选填"
          ></el-input>
        </div> -->

        <div class="coupons_bottom" v-if="discountObj == null">
          <div class="text_left">无优惠</div>
          <div class="text_right">- ¥ 0.00</div>
        </div>

        <div class="coupons_bottom" v-else>
          <div class="text_left">{{ discountObj.codeName }}</div>
          <div class="text_right">
            - ¥
            {{
              specificationitem.price
                ? (
                    specificationitem.price * sum -
                    discountObj.discountPrice * sum
                  ).toFixed(2)
                : (price * (1 - discountObj.discountValue)).toFixed(2)
            }}
          </div>
        </div>
      </div>

      <div class="pay_box">
        <div
          class="pay_item"
          :class="payId == item.id ? 'bk' : ''"
          v-for="item in payList"
          @click="payId = item.id"
          :key="item.id"
        >
          <img :src="item.img" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>

      <div class="payment_text" v-if="discountObj == null">
        应付款:
        <span class="text"
          >¥
          {{
            specificationitem.price
              ? (specificationitem.price * sum).toFixed(2)
              : price.toFixed(2)
          }}</span
        >
      </div>

      <div class="payment_text" v-else>
        应付款:
        <span class="text"
          >¥
          {{
            specificationitem.price
              ? (discountObj.discountPrice * sum).toFixed(2)
              : (price * discountObj.discountValue).toFixed(2)
          }}</span
        >
      </div>

      <div slot="footer" class="dialog-footer" v-if="discountObj == null">
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{
              specificationitem.price
                ? (specificationitem.price * sum).toFixed(2)
                : price.toFixed(2)
            }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>

      <div slot="footer" class="dialog-footer" v-else>
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{
              specificationitem.price
                ? (discountObj.discountPrice * sum).toFixed(2)
                : (price * discountObj.discountValue).toFixed(2)
            }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>
    </el-dialog>

    <!-- 扫码支付 -->
    <el-dialog
      title="扫码支付"
      :close-on-click-modal="false"
      :visible.sync="paymentPopup"
      @close="handleClosePaymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeurl.codeImg" alt="" />
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        <div class="price" v-if="discountObj == null">
          应付款：<span
            >￥<span>{{
              specificationitem.price
                ? (specificationitem.price * sum).toFixed(2)
                : price.toFixed(2)
            }}</span></span
          >
        </div>
        <div class="price" v-else>
          应付款：<span
            >￥<span>{{
              specificationitem.price
                ? (discountObj.discountPrice * sum).toFixed(2)
                : (price * discountObj.discountValue).toFixed(2)
            }}</span></span
          >
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="paymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">支付成功,是否前去订单中心</div>
      <div class="footer">
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
            $router.push({
              name: 'OrderCenter',
            });
          "
        >
          确定
        </div>
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
          "
        >
          取消
        </div>
      </div>
    </el-dialog>

    <!-- 添加成功 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="addDialog"
      class="paymentSuccess"
    >
      <div class="content">添加成功,是否前去下载客户端</div>
      <div class="footer">
        <div
          class="footer-btn"
          @click="
            addDialog = false;
            $router.push({
              name: 'DownloadGuide',
            });
          "
        >
          确定
        </div>
        <div class="footer-btn" @click="addDialog = false">取消</div>
      </div>
    </el-dialog>

    <!-- 会员免费添加弹窗 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="VIPFreeAdd"
      class="VIPFreeAdd"
    >
      <div class="content">
        尊享会员权益,会员价0元购。
        <br />
        您还剩{{ goodsData.contextualNum }}次免费机会，确认添加？
      </div>

      <div class="footer">
        <div class="footer-btn" @click="ShowVIPFreeAdd">确定</div>
        <div class="footer-btn" @click="VIPFreeAdd = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchSpecification,
  orderAdd,
  payOtherOrderPay,
  PaywechatOrderQueryQrcode,
  AddCopyrightPractice,
  searchFile,
  addUserLibrary,
  sideIsbuy,
  findDiscount,
  bind,
  addOrDel,
  alipayQueryOrder,
  findDiscountByUser,
} from "api/goodsGetails";
import { pcOrderPay } from "api/common";
import qs from "qs";
import { addOftenUseLibrary } from "api/goldenSentenceIndex.js";
import PictureShowcase from "components/GoodsGetailsComponent/PictureShowcase";
import Specifications from "components/GoodsGetailsComponent/Specifications";
// import CopyrightCounter from "components/GoodsGetailsComponent/CopyrightCounter";

export default {
  name: "GoodsGetailsInfo",
  components: {
    Specifications,
    PictureShowcase,
  },
  props: {
    goodsData: {},
  },
  data() {
    return {
      payId: 1,
      payList: [
        {
          name: "微信",
          id: 1,
          img: require("assets/images/weixin.png"),
        },
        {
          name: "支付宝",
          id: 2,
          img: require("assets/images/zhifubao.png"),
        },
      ],
      // 会员免费添加弹窗
      VIPFreeAdd: false,

      // 添加弹窗
      addDialog: false,
      // 优惠码输入框
      codeInput: "",

      // 商品规格
      goodsSpecifications: [
        { name: "选择流程", title: "选择流程" },
        { name: "选择承办人", title: "选择承办人" },
      ],
      // 选中的规格
      specificationitem: {},
      // 选择流程弹窗
      showProcess: false,
      // 选择承办人弹窗
      showUndertaker: false,
      // 选中流程数组工时
      needtime: 0,
      // 选择承办人数组
      undertakerList: [
        {
          userId: 0,
          createTime: "2022-03-08T15:32:01",
          nickName: 0,
          userLevel: 0,
          payPrice: 0,
          occupation: 0,
          education: 0,
        },
      ],
      // 选中承办人
      undertaker: 1,
      // 是否喜欢
      like: false,
      // 支付成功弹窗
      paymentSuccessPopup: false,
      // 扫码弹窗
      paymentPopup: false,
      // 确认订单弹窗
      addShopDialog: false,
      // 商品数量
      sum: 1,
      // 价格
      price: 0,
      // 商品id
      id: "",
      // 订单 oid
      oid: "",
      // 商品图片
      imgList: false,
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      // 类型
      type: this.$route.params.type,
      discountObj: null,
      isFreeAdd: true,
    };
  },
  methods: {
    bgimgClick() {
      window.OpenStencilList();
    },
    //收藏商品
    async lick() {
      let data = {
        productId: this.id,
      };
      let res = await addOrDel(data);
      if (res.code == 200) {
        this.like = !this.like;
        if (this.like) {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "取消收藏",
          });
        }
      }
    },
    // 搜索查询商品规格
    async goodsspecifications(id) {
      this.goodsSpecifications = [];
      // productType 4 取消请求
      if (this.goodsData.productType == 4) {
        this.goodsSpecifications = [
          { name: "选择流程", title: "选择流程" },
          { name: "选择承办人", title: "选择承办人" },
        ];
        return;
      }
      const data = {
        productId: id,
        name: "",
      };

      const res = await searchSpecification(data);

      if (res.code === 200) {
        this.specificationitem = res.data[0];
        this.goodsSpecifications = res.data;
      } else if (res.code === 2001) {
        this.specificationitem.price = 0;
        this.goodsSpecifications = res.data;
      } else {
        this.$message({
          type: "error",
          message: "获取商品规格失败",
        });
      }
    },

    // 搜索查询商品图片
    async goodsfils(id) {
      const data = {
        productId: id,
      };
      // JSON.stringify(data)
      const res = await searchFile(data);
      if (res.code === 200) {
        this.imgList = res.data;
      } else if (res.code === 2001) {
        this.imgList = res.data;
      } else {
        this.$message({
          type: "error",
          message: "获取商品图片失败",
        });
      }
    },

    // 监听优惠码输入框
    codeHandelInput(val) {
      if (val.length == 6) {
        this.bindingCode(val);
      }
    },

    //  绑定推荐码
    async bindingCode(val) {
      const data = {
        code: val,
      };
      const res = await bind(data);
      if (res.code == 200) {
        this.getDiscount(val);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // vip/svip优惠
    async vipDiscounts() {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
      };
      const res = await findDiscountByUser(data);
      if (res.code === 200) {
        this.addShopDialog = true;
        this.discountObj = res.data;
        this.codeInput = res.data.code;
      } else if (res.code === 2023) {
        this.addShopDialog = true;
        this.discountObj = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 获取优惠
    async getDiscount(code) {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
        code,
      };
      const res = await findDiscount(data);
      if (res.code === 200) {
        this.addShopDialog = true;
        this.discountObj = res.data;
        this.codeInput = res.data.code;
      } else if (res.code === 2023) {
        this.addShopDialog = true;
        this.discountObj = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    //选择规格
    handleSelect(item) {
      this.specificationitem = item;
      if (item.title == "选择流程") {
        this.showProcess = true;
      }
      if (item.title == "选择承办人") {
        this.showUndertaker = true;
      }
    },

    // 商品数量
    childByValue(sum) {
      this.sum = sum;
    },

    // 立即购买
    async addToShopcar() {
      if (this.$store.state.user_info) {
        if (this.goodsData.productPrices != 0) {
          this.addShopDialog = true;
          // this.getDiscount();
          this.vipDiscounts();
        } else {
          this.addUserLibrary();
        }
      } else {
        window.loginFun();
      }

      // this.registerClick();
    },
    // 会员免费添加
    ShowVIPFreeAdd() {
      this.VIPFreeAdd = false;
      this.isFreeAdd = false;

      if (this.goodsData.contextualNum - 1 == 0) {
        this.goodsData.memberPay = false;
      }
      this.goodsData.contextualNum = this.goodsData.contextualNum - 1;
      this.addUserLibrary();
    },

    IsVIPFreeAdd() {
      if (!this.isFreeAdd) {
        console.log(1);
        this.$message({
          type: "success",
          message: "该商品已添加，请勿重复添加",
        });
        return;
      }
      if (this.goodsData.productPrices == 0) {
        return this.addUserLibrary();
      }
      // if (
      //   this.goodsData.productPrices != 0 &&
      //   this.goodsData.memberPay == true &&
      //   this.goodsData.contextualNum
      // ) {
      //   this.VIPFreeAdd = true;
      //   return;
      // }

      if (this.goodsData.memberPay == true) {
        return this.addUserLibrary();
      }

      // this.VIPFreeAdd = true;
    },

    //立即添加
    async addUserLibrary() {
      let data = {
        productCode: this.goodsData.productCode,
      };
      const res = await addUserLibrary(data);
      if (res.code === 200) {
        this.addDialog = true;
        this.$message({
          type: "success",
          message: res.message,
        });
      }
    },

    //金句立即添加
    async addLibrary(item) {
      let formData = new FormData();
      formData.append("libId", item.id);
      formData.append("libType", item.libType);
      const res = await addOftenUseLibrary(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "添加句库成功！",
        });
        this.getaddedList();
        this.getNotaddedList();
      }
    },

    //授权生成支付二维码
    async QrCode() {
      let that = this;
      const data = {
        oid: this.oid,
      };

      const res = await payOtherOrderPay(data);

      if (res.code === 200) {
        this.paymentPopup = true;
        this.qrCodeurl = res.data;
        this.timer = window.setInterval(function () {
          that.wechatOrderQueryQrcode();
        }, 1500);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 调用微信支付的查询订单接口
    async wechatOrderQueryQrcode() {
      const data = {
        oid: this.oid,
      };

      const res = await PaywechatOrderQueryQrcode(data);
      if (res.code == 200) {
        window.clearInterval(this.timer);
        this.$message.success("支付成功");
        if (this.$store.state.shopNav) {
          this.$router.go(-1);
        }
        this.paymentSuccessPopup = true;
      }
    },

    // 新增订单
    async submitOrder() {
      if (this.goodsData.productType == 4) {
        return this.addCopyrightPractice();
      }
      const data = {
        productId: this.id,
        productSpecificationId: this.specificationitem.id,
        quantity: this.sum,
        payType: this.payId == 1 ? 1 : 2,
      };
      const res = await orderAdd(data);
      if (res.code === 200) {
        this.oid = res.data;
        if (this.payId == 1) {
          this.QrCode();
        } else {
          this.AlipaypcOrderPay(this.oid);
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 支付宝支付
    async AlipaypcOrderPay(oid) {
      const data = {
        oid,
      };

      const res = await pcOrderPay(qs.stringify(data));
      const divForm = document.getElementById("divForm");
      if (divForm) {
        document.body.removeChild(divForm);
      }
      const div = document.createElement("div");
      div.id = "divForm"; // 设置id
      div.innerHTML = res.data;
      document.body.appendChild(div);
      // 利用id去获取表单
      document
        .getElementById("divForm")
        .children[0].setAttribute("target", "_blank");
      document.getElementById("divForm").children[0].submit();

      this.timer = window.setInterval(() => {
        this.getzfbOrderStatus(oid);
      }, 1000);
    },

    // 查询支付订单状态
    async getzfbOrderStatus(oid) {
      const res = await alipayQueryOrder(qs.stringify({ oid }));

      if (res.data === 3) {
        window.clearInterval(this.timer);
        this.$message.success("支付成功");
        if (this.$store.state.shopNav) {
          this.$router.go(-1);
        }
        this.paymentSuccessPopup = true;
      }
    },

    // 新增版权实务订单
    async addCopyrightPractice() {
      const data = {
        productId: this.id,
        projectdetailIds: this.projectdetailIds,
        handleId: this.undertaker.userId,
      };
      const res = await AddCopyrightPractice(data);
      if (res.code === 200) {
        this.oid = res.data;
        this.QrCode();

        // this.paymentSuccessPopup = true;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      window.clearInterval(this.timer);
    },
  },

  watch: {
    goodsData: {
      handler(newVal, oldVal) {
        // newVal是获取的是新的值
        this.goodsData = newVal;
        this.goodsspecifications(this.$route.params.id);
      },
      deep: true,
    },
    $route(to, from) {
      this.goodsfils(this.$route.params.id);
      // console.log(this.$route.params.id);
      this.id = this.$route.params.id;
    },
  },
  created() {
    // console.log(this.$route.params.id);
    this.id = this.$route.params.id;

    this.goodsspecifications(this.$route.params.id);
    this.goodsfils(this.$route.params.id);
  },
  beforeDestroy() {
    //页面销毁前，清除定时器
    window.clearInterval(this.timer); //清除timer定时器
  },
};
</script>

<style lang="scss" scoped>
.GoodsGetailsInfo {
  .copyright-practice-detail-content {
    margin-top: 20px;

    .copyright-practice-detail-content-main {
      display: flex;

      .copyright-practice-detail-content-main-desc {
        flex: 1;
        margin-left: 40px;

        .title {
          margin: 0 0 15px 0;
          font-size: 18px;
          font-weight: bold;
        }

        .product-info {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          padding-bottom: 20px;
          border-bottom: 1px solid #eceff1;
        }

        .block {
          @include flex-start;

          padding: 15px 0 0 0;

          position: relative;

          .leftTemp {
            // height: 225px;
            .specification .space,
            .specification .specification_text,
            .price .space {
              display: inline-block;
              width: 70px;
              text-align-last: justify;
              text-align: justify;

              margin-right: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
            .specification {
              display: flex;
              align-items: center;

              .shuju {
                display: flex;
              }
              .bgimg {
                margin-left: 20px;
                display: inline-block;
                width: 18px;
                height: 18px;
                background: url("~assets/list.png") no-repeat;
                background-size: 100% 100%;

                &:hover {
                  background: url("~assets/list_blue.png") no-repeat;
                  background-size: 100% 100%;
                }
              }
              .txt {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }
            }
            .price {
              display: flex;
              align-items: center;
              .txt {
                display: flex;
                flex-direction: column;
                .small {
                  // line-height: 0.8;
                  font-size: 18px;
                  font-weight: 800;
                  display: flex;
                  align-items: center;

                  .BeforePrice {
                    margin-left: 15px;
                    font-size: 14px;
                    color: #999;
                    text-decoration: line-through;
                  }
                }
                font-size: 15px;
                font-weight: 500;
                color: #eb441e;

                .vip_price {
                  margin-top: 10px;
                  color: #000;
                  display: flex;
                  align-items: center;
                  font-size: 16px;

                  .text {
                    padding: 5px;
                    border-radius: 2px;
                    border: #eb441e 1px solid;
                    margin: 0 10px;
                    color: #eb441e;
                    font-size: 12px;
                  }

                  span {
                    font-size: 12px;
                  }
                }
              }
            }
            .margin {
              margin-top: 10px;
            }
          }

          .right {
            @include flex-center(column);
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
            .evaltxt {
              white-space: nowrap;
              color: #666666;
            }
            .evalnum {
              margin-top: 10px;
              color: $color-text-active;
            }
          }
        }

        .last {
          display: flex;
          margin: 25px 0;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          .text {
            min-width: 70px;
            text-align-last: justify;
            text-align: justify;
            margin-right: 20px;
          }

          .busin {
            color: #000;
          }
          .follow {
            cursor: pointer;
          }
          .follow {
            user-select: none;
            span {
              margin-left: 5px;
            }
            .followed-txt {
              color: #666666;
            }
            .txt {
              color: $color-text-active;
            }
          }
        }

        .addshop {
          @include flex-start;
          margin-top: 15px;
          .shopcarBtn {
            width: 160px;
            height: 48px;
            margin-left: 10px;
            background: #eb441e;
            border-radius: 2px;
            text-align: center;
            line-height: 48px;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .copyright-practice-detail-content-sets {
      @include noselect;
      @include flex-start;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      cursor: pointer;
      .collect {
        margin-right: 20px;
      }
      .collect,
      .share {
        @include flex-start;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}

// top: 50%;
//     transform: translateY(-50%);
//     margin-top: 0 !important;
// 立即购买弹窗
.addShopDialog {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
  .shop_box {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #cccc;
    display: flex;
    // justify-content: center;
    align-items: center;
    .shop_left {
      margin: 0 10px;
      .img {
        width: 60px;
        height: 60px;
      }
    }

    .shop_right {
      display: flex;
      flex: 1;
      flex-direction: column;
      .txet_top {
        color: #000;
        font-size: 16px;
        margin: 0 0 15px 0;
      }

      .text_bottom {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 0;
        font-size: 14px;
        .text_left {
          color: #999999;
        }

        .text_center {
          color: #999999;
        }

        .text_right {
          color: #eb441e;
        }
      }
    }
  }
  .coupons_box {
    padding: 20px 0;
    border-bottom: 1px solid #cccc;
    .coupons_top {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      .icon {
        width: 14px;
        height: 10px;
        @include backgroundGroup("@~assets/images/coupons.png");
        margin: 0 10px 0 5px;
      }

      .el-input {
        width: 150px;
        height: 30px;
        margin-left: 20px;
        ::v-deep .el-input__inner {
          height: 30px;
        }
      }
    }

    .coupons_bottom {
      margin-top: 15px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 30px;
      .text_left {
        color: #999999;
      }

      .text_right {
        color: #eb441e;
      }
    }
  }
  .pay_box {
    margin-top: 20px;
    display: flex;
    .pay_item {
      cursor: pointer;
      box-sizing: border-box;
      padding: 20px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
      }
    }

    .bk {
      border: 1px solid red;
    }
  }

  .payment_text {
    padding: 20px 0 70px 0;
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: #000;
    .text {
      color: #eb441e;
      margin-right: 15px;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    .payment_text {
      padding: 0;
      font-weight: 800;
      font-size: 16px;
    }
  }
}

// 支付弹窗
.payment {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 500px;
    height: 390px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #f4f6f7;
      padding: 0;
      line-height: 42px;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      padding: 65px 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notes {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }
        .imgBox {
          width: 110px;
          height: 110px;
          margin-top: 30px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .price {
          margin-top: 30px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
          span {
            color: #eb441e;
            span {
              font-size: $font-size-large;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// 支付成功弹窗
.paymentSuccess {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 340px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px 39px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}

// 会员免费添加弹窗
.VIPFreeAdd {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 340px;
    min-height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px 39px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .wm-antiplagiarism-header {
  width: 0;
  height: 0;
  overflow: hidden;
  min-width: auto;
}
</style>
